var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":""}},[_c('b-table',{staticClass:"text-left",attrs:{"responsive":"sm","small":"","fields":_vm.fields,"items":_vm.items.slice((_vm.currentPage - 1) * _vm.perPage, _vm.currentPage * _vm.perPage)},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.notes ? 'Obs.: '+ item.notes :'Sem descrição'))])]}},{key:"cell(project)",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.project_code))]),_c('p',[_vm._v(_vm._s(item.project_name))])]}},{key:"cell(actions)",fn:function(props){return [_c('div',{on:{"click":function($event){return _vm.handleUpdateModal(props.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],attrs:{"title":"Editar fornecedor","icon":"EditIcon","size":"15"}}),_vm._v("Editar ")],1)]}},{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
          })},[_c('span',{style:({
              borderRadius: '50%',
              //   display: 'block',
              background: item.color,
              width: '10px',
              height: '10px',
            })}),_c('p',{style:({
              height: 'auto',
              paddingLeft: '5px',
              paddingTop: '10px',
            })},[_vm._v(" "+_vm._s(item.username)+" ")])]),_c('small',[_vm._v(_vm._s(item.email))])])]}},{key:"cell(date)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.date, 'dd/MM/yyyy'))+" ")]}},{key:"cell(start)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.start.slice(0,5))+" ")]}},{key:"cell(end)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.end.slice(0,5))+" ")]}},{key:"cell(comments)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.notes)+" ")]}}])}),_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }