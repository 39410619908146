<template>
  <b-card title="">
    <b-table
      responsive="sm"
      small
      :fields="fields"
      :items="items.slice((currentPage - 1) * perPage, currentPage * perPage)"
      class="text-left"
    >
      <template v-slot:cell(title)="{ item }">
        <strong>{{ item.title }}</strong><br>
        <small> {{ item.notes ?  'Obs.: '+ item.notes :'Sem descrição' }}</small>
      </template>
      <template v-slot:cell(project)="{ item }">
        <strong>#{{ item.project_code }}</strong>
        <p>{{ item.project_name }}</p>
      </template>
      <template v-slot:cell(actions)="props">
        <div @click="handleUpdateModal(props.item)">
          <feather-icon
            v-b-tooltip.hover.lefttop.v-primary
            title="Editar fornecedor"
            icon="EditIcon"
            size="15"
          />Editar
        </div>
      </template>
      <template v-slot:cell(username)="{ item }">
        <div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <span
              :style="{
                borderRadius: '50%',
                //   display: 'block',
                background: item.color,
                width: '10px',
                height: '10px',
              }"
            >
            </span>
            <p
              :style="{
                height: 'auto',
                paddingLeft: '5px',
                paddingTop: '10px',
              }"
            >
              {{ item.username }}
            </p>
          </div>
          <small>{{ item.email }}</small>
        </div>
      </template>

      <template v-slot:cell(date)="{ item }">
        {{ dateFormatter(item.date, 'dd/MM/yyyy') }}
      </template>
      <template v-slot:cell(start)="{ item }">
        {{ item.start.slice(0,5)}}
      </template>
      <template v-slot:cell(end)="{ item }">
        {{ item.end.slice(0,5) }}
      </template>
      <template v-slot:cell(comments)="{ item }">
        {{item.notes }}
      </template>
    </b-table>

    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import { BCard, BTable, BPagination, VBTooltip } from 'bootstrap-vue';

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BPagination,
  },
  props: {
    items: { type: Array, require: true },
  },
  data: () => ({
    modalNewSupplier: false,
    modalDeleteSupplier: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    fields: [
      {
        key: 'title',
        label: 'Tarefa',
        sortable: true,
        thStyle: { width: '20%' },
      },
      {
        key: 'project',
        label: 'Projeto',
        sortable: true,
        thStyle: { width: '20%' },
      },
      {
        key: 'actions',
        label: 'Ações',
        thStyle: { width: '20%' },
      },
      {
        key: 'username',
        label: 'Usuário',
        sortable: true,
      },
      {
        key: 'date',
        label: 'Data',
        sortable: true,
      },
      {
        key: 'start',
        label: 'Início',
        sortable: true,
      },
      {
        key: 'end',
        label: 'Fim',
        sortable: true,
      },
    ],
    taxRecordsList: false,
  }),
  created() {},
  watch: {
    items: {
      handler(newItems) {
        this.totalRows = newItems.length;
      },
      deep: true,
    },
  },

  methods: {
    closeModal() {
      this.modalNewSupplier = false;
      this.modalDeleteSupplier = false;
    },

    cleanFilter() {
      this.modelTaxRecords.search = '';
    },

    openDeleteModal(item) {
      this.modalDeleteSupplier = true;
      this.$store.modalSupplier = item;
    },

    handleUpdateModal(item) {
      const formattedData = {
        ...item,
        date: item.date,
        startTime:item.start.slice(0,5),
        endTime: item.end.slice(0,5),
        name: item.title,
        comments: item.notes,
      };
      this.$emit('openUpdateModal', formattedData);
    },
    handleUpdateList() {
      this.getAllSuppliers();
    },
  },
};
</script>
