var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',[_c('b-button',{staticClass:"mb-4",attrs:{"block":"","variant":"success"},on:{"click":_vm.openModalAction}},[_vm._v("Criar nova tarefa")]),_c('div',{style:({
      overflowX: 'auto',
      width: '100%',
      display: 'flex'
    })},_vm._l((_vm.users),function(user){return _c('div',{key:user.id,style:({
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center'
      })},[_c('input',{attrs:{"type":"checkbox","checked":""},on:{"change":function($event){return _vm.getTasksBySelect(user.member_id)}}}),_c('span',{staticClass:"cutom-bullet",style:({
          borderRadius: '50%',
          display: 'block',
          color: user.color,
          background: user.color,
          width: '15px',
          height: '15px'
        }),on:{"click":function($event){return _vm.handleClickBullet(user)}}}),_c('div',{style:({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        })},[_c('p',{style:({
            marginRight: '10px',
            marginTop: '12px'
          })},[_vm._v(" "+_vm._s(user.username)+" ")])])])}),0),_c('div',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":_vm.swtichAllowInactivate},model:{value:(_vm.allowInactivates),callback:function ($$v) {_vm.allowInactivates=$$v},expression:"allowInactivates"}},[_vm._v(" Mostrar inativos? ")])],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold text-primary",attrs:{"for":"category"}},[_vm._v("Data inicio :")]),_c('b-form-input',{attrs:{"id":"transactionDate","type":"date"},on:{"change":_vm.updateSchedulerWeek},model:{value:(_vm.start_week_day),callback:function ($$v) {_vm.start_week_day=$$v},expression:"start_week_day"}})],1)],1),_c('b-col',[_c('b-button',{staticClass:"btn-tour-skip btn-list mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.list = !_vm.list}}},[_vm._v(_vm._s(_vm.list ? 'Agenda' : 'Lista')+" ")])],1)],1),_c('div',{staticClass:"calendar-wrapper"},[(!_vm.list)?_c('div',[_c('vue-scheduler',{ref:"scheduler",staticClass:"custom-scheduler",attrs:{"locale":"pt-br","min-date":null,"max-date":null,"labels":{
          today: 'Hoje',
          back: 'Anterior',
          next: 'Seguinte',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          all_day: 'Todos os dias'
        },"time-range":[0, 23],"available-views":[],"active-date":_vm.schedulerInitialDate,"initial-view":"week","showTodayButton":true,"events":_vm.events,"event-display":_vm.eventDisplay,"disableDialog":""},on:{"month-changed":_vm.monthChanged,"week-changed":_vm.weekChanged,"day-changed":_vm.dayChanged,"view-changed":_vm.viewChanged,"day-clicked":_vm.dayClicked,"time-clicked":_vm.timeClicked,"event-clicked":_vm.eventClicked,"event-created":_vm.eventCreated}})],1):_c('div',[_c('List',{attrs:{"items":_vm.items},on:{"openUpdateModal":_vm.handleUpdateModalEmit}})],1)]),_c('Modal',{attrs:{"pre_time":_vm.preset_data,"open":_vm.modalOpen},on:{"closeModal":_vm.closeModal}}),_c('ModalUpdate',{attrs:{"_item":_vm.eventSelect,"open":_vm.modalUpdate},on:{"closeModal":_vm.closeModal}}),_c('BModal',{attrs:{"title":"Alterar cor de usuário","hide-footer":""},model:{value:(_vm.userColorChanger.modalOpen),callback:function ($$v) {_vm.$set(_vm.userColorChanger, "modalOpen", $$v)},expression:"userColorChanger.modalOpen"}},[_c('b-form',[_c('b-row',{staticClass:"my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"},[_c('b-col',{attrs:{"col":"","xs":"12","sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"text-center font-weight-bold text-primary",attrs:{"for":"value"}},[_c('strong',[_vm._v(_vm._s(_vm.userColorChanger.username)+":")])]),_c('b-form-input',{attrs:{"id":"value","type":"color"},model:{value:(_vm.userColorChanger.color),callback:function ($$v) {_vm.$set(_vm.userColorChanger, "color", $$v)},expression:"userColorChanger.color"}})],1)],1)],1),_c('b-button',{attrs:{"block":"","variant":"warning"},on:{"click":function($event){return _vm.handleChangeColor()}}},[_vm._v("Confirmar")]),_c('b-button',{attrs:{"block":""},on:{"click":function($event){_vm.userColorChanger.modalOpen = false}}},[_vm._v("Voltar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }